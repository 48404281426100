<template>
  <dialog-content
    :dialog-title="'Cancel Order Item?'"
    :buttons="[
      {
        label: 'Cancel',
        onClick: () => {
          confirm(false)
        }
      },
      {
        label: 'Ok',
        onClick: () => {
          confirm(true)
        }
      }
    ]"
  >
    <p
      data-testid="confirm-cancel-status-count-message"
      class="px-2 sm:px-0 whitespace-pre-line text-sm text-gray-500 leading-6"
    >
      {{ dialogMessage }}
    </p>
    <div class="pt-4" v-if="!onlyOneCount">
      <check-box-input
        label="Cancel All Counts"
        v-model:checked="cancelEntireItem"
        :editable="true"
      />
    </div>

    <div v-if="!cancelEntireItem">
      <div>
        <list-input
          :label="'Status To Cancel: '"
          :options="statuses"
          v-model:selected="selectedStatus"
          :editable="!onlyOneStatus"
        />
      </div>

      <div>
        <number-input
          :label="'Count To Cancel: '"
          :number="maxCount"
          @update:number="selectedCount = $event"
          :editable="true"
          :min="1"
          :max="maxCount"
          :placeholder="maxCount"
          :show-of-max="true"
        />
      </div>
    </div>
    <div
      v-if="alert"
      :class="alertClass"
      class="pt-4 transition-all"
      data-testid="confirm-cancel-status-count-alert-message"
    >
      <alert-message-component :message="alert.message" :message-type="alert.messageType" />
    </div>
  </dialog-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { confirmCancelStatusCountDialogClosed } from '@/modal_dialogs/dialog-helpers.ts'
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'
import NumberInput from '@/components/inputComponents/NumberInput.vue'
import ListInput from '@/components/inputComponents/ListInput.vue'
import {
  cancelableOrderItemStatuses,
  type LocalOrderItem,
  LocalOrderItemStatusCount,
  type LocalOrderItemStatusEnum
} from '@/services/DataServices'
import CheckBoxInput from '@/components/inputComponents/CheckBoxInput.vue'
import AlertMessageComponent, { type MessageType } from '@/components/AlertMessageComponent.vue'

export default defineComponent({
  name: 'ConfirmCancelStatusCountDialogContent',
  emits: ['update:dialogOpen'],
  components: { AlertMessageComponent, CheckBoxInput, ListInput, NumberInput, DialogContent },
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
      default: true
    },
    dialogProp: {
      type: Object as PropType<object>,
      required: true
    },
    cancel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    alertClass(): string {
      return !this.cancelAllRemainingStatusCounts ? 'cursor-default opacity-0 select-none' : ''
    },
    item(): LocalOrderItem {
      return this.propObject.item
    },
    statuses(): LocalOrderItemStatusEnum[] {
      if (!this.item.statuses) return []
      return this.item.statuses.filter((stsEnum) => cancelableOrderItemStatuses.includes(stsEnum))
    },
    onlyOneStatus(): boolean {
      return this.statuses.length < 2
    },
    onlyOneCount(): boolean {
      return this.item.statusCountsOrCount === 1
    },
    dialogMessage(): string {
      if (this.propObject.message) return this.propObject.message
      return this.cancelEntireItem
        ? 'Confirm Cancellation For Item.'
        : 'Confirm Cancellation For Count Of Item Products In Status'
    },
    alert(): { message: string; messageType?: MessageType } | undefined {
      return this.propObject.alert
    },
    maxCount(): number {
      const selectedStatusCount: number | undefined = this.item.statusAndCountOrStatusCount.find(
        (sc) => sc.status == this.selectedStatus
      )?.count
      return selectedStatusCount ?? 1
    },
    cancelAllRemainingStatusCounts(): boolean {
      // Will be compared to all remaining status counts, not just the count of the currently selected status.
      // So, if there are multiple provisioning statuses left, it won't show the alert warning for canceling all counts of the selected status.
      const cancelAllCounts = this.item.cancelableStatusCountsOrCount === this.selectedCount
      return this.cancelEntireItem || cancelAllCounts
    }
  },
  data() {
    return {
      propObject: this.dialogProp as {
        item: LocalOrderItem
        message?: string
        alert?: { message: string; messageType?: MessageType }
      },
      cancelEntireItem: true as boolean,
      selectedStatus: undefined as LocalOrderItemStatusEnum | undefined,
      selectedCount: 0 as number
    }
  },
  created() {
    const item = (this.$props.dialogProp as { item: LocalOrderItem }).item
    this.selectedStatus = item.cancelableStatusCounts[0].status
    this.selectedCount = this.maxCount
  },
  methods: {
    confirm(confirmed: boolean) {
      let statusCount = new LocalOrderItemStatusCount({
        status: this.selectedStatus,
        count: this.selectedCount
      })
      confirmCancelStatusCountDialogClosed(confirmed, this.cancelEntireItem, statusCount)
      this.$emit('update:dialogOpen', false)
    }
  },
  watch: {
    cancel: {
      immediate: true,
      handler(newValue) {
        if (newValue === true) {
          this.confirm(false)
        }
      }
    },
    cancelEntireItem: {
      immediate: true,
      handler() {
        this.selectedCount = this.maxCount
      }
    }
  }
})
</script>

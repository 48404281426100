import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalizedGeneric
} from 'vue-router'
import OrdersView from '@/views/OrdersView.vue'
import LoginView from '@/views/LoginView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import ItemsView from '@/views/ItemsView.vue'
import SKUView from '@/views/SKUView.vue'
import csawfApi from '@/csawf-api'
import FaultsView from '@/views/FaultsView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/orders',
      name: 'orders',
      component: OrdersView
    },
    {
      path: '/items',
      name: 'items',
      component: ItemsView
    },
    {
      path: '/skus',
      name: 'skus',
      component: SKUView
    },
    {
      path: '/faults',
      name: 'faults',
      component: FaultsView
    },
    { path: '/:pathMatch(.*)*', component: NotFoundView }
  ]
})

router.beforeEach((to, from, next): void => {
  csawfApi.getLoggedInState().then((loggedIn: boolean): void => {
    handleTestSentryError(to)
    csawfApi.updateUser(loggedIn)
    handleRouting(to, next, loggedIn)
  })
})

function handleRouting(
  to: RouteLocationNormalizedGeneric,
  next: NavigationGuardNext,
  loggedIn: boolean
): void {
  if (LoginRequired(to) && !loggedIn) goToLoginPage(to, next)
  else if (shouldLogOut(to, loggedIn)) csawfApi.logout()
  else if (isDefaultTargetPath(to)) goToOrdersPage(next)
  else next()
}

function handleTestSentryError(to: RouteLocationNormalizedGeneric): void {
  if (to.path.includes('sentry-debug-frontend')) throw new Error('Sentry Debug Error')
}

function shouldLogOut(target: RouteLocationNormalizedGeneric, isLoggedIn: boolean): boolean {
  const targetIsLogout: boolean = target.path.includes('logout')
  const targetIsLoginAndUserIsAlreadyLoggedIn: boolean = isLoggedIn && target.path === '/login'
  return targetIsLogout || targetIsLoginAndUserIsAlreadyLoggedIn
}

function goToLoginPage(to: RouteLocationNormalizedGeneric, next: NavigationGuardNext): void {
  next({ path: 'login', replace: true, query: { redirect: to.fullPath } })
}

function goToOrdersPage(next: NavigationGuardNext): void {
  next({ path: 'orders', replace: true })
}

function isDefaultTargetPath(target: RouteLocationNormalizedGeneric): boolean {
  return target.path === '/' || target.path === '' || target.path === '/index'
}

const publicPages: Array<string> = ['/login']

function LoginRequired(targetRoute: RouteLocationNormalizedGeneric): boolean {
  return !publicPages.includes(targetRoute.path)
}

export default router

<template>
  <page-header text="SKUs">
    <div
      id="createNewSkuButton"
      v-tooltip="'Create New SKU'"
      class="cursor-pointer justify-center align-baseline items-end p-2 sm:p-2 flex w-12 h-12 sm:h-10 sm:w-10 text-gray-600 hover:text-gray-950 orderItemHeader hover:bg-slate-600/[0.1] rounded-3xl shadow-[1px_1px_4px_0_rgba(0,0,0,0.4)]"
      @click="openNewSkuDialog"
    >
      <document-plus-icon
        style="padding-left: 1px; padding-bottom: 1px"
        class="h-full w-full"
        aria-hidden="true"
      />
    </div>
  </page-header>
  <table-component
    :retrieve-table-data-function="retrieveTableData"
    :update-on-change="updateKey"
    :unique-key="'id'"
    :process-field-function="processFieldFunction"
    :click-function="openSkuDialog"
    :hidden-fields="['updated', 'Name', 'id']"
    :small-screen-hidden-fields="['created', 'description', 'child_skus']"
    :process-column-header-function="processColumnHeaderFunction"
    :display-text-input-filter="true"
    :default-active-column="'label'"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { SkuChildSkusInner } from '@/api'
import TableComponent from '@/components/TableComponent.vue'
import PageHeader from '@/components/PageHeader.vue'
import { errorHandler } from '@/services/ErrorHandler'
import { ref } from 'vue'
import { createUpdatedSkusListener } from '@/composables'
import { DocumentPlusIcon } from '@heroicons/vue/24/outline'
import {
  api,
  designTypes,
  Filter,
  LocalSku,
  skuTypes,
  stringLabelCompare
} from '@/services/DataServices'
import type { DataSet } from '@/services/DataServices'
import TableIconComponent from '@/components/TableIconComponent.vue'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid'
import { processHumanReadableDate } from '@/services/DateTimeServices.ts'
import { newSkuDialog } from '@/modal_dialogs/dialog-helpers.ts'

export default defineComponent({
  name: 'SKUView',
  data() {
    return {
      updateKey: 0 as number,
      relevantSKUs: [] as LocalSku[],
      openSkuDialog: ref((sku: LocalSku) => {
        newSkuDialog(sku, undefined, false, true)
      }),
      openNewSkuDialog: ref(() => {
        newSkuDialog({} as LocalSku)
      })
    }
  },
  methods: {
    async retrieveTableData(
      numResults: number,
      startIndex: number,
      stringFilter?: string,
      orderBy?: string,
      // prettier-ignore
      filterObjectArray?: Filter[] // eslint-disable-line
    ): Promise<DataSet<LocalSku>> {
      if (orderBy) {
        if (orderBy.includes('material')) {
          orderBy = orderBy.replace('material', 'material__label')
        }
        if (orderBy.includes('child_skus')) {
          // return api.getSkusSet(numResults, startIndex, stringFilter || undefined, undefined)
          orderBy = orderBy.replace('material', 'child_skus')
        }
      }
      const set = await api.getSkusSet(numResults, startIndex, stringFilter, orderBy)
      await this.retrieveRelevantSkus([...set.data])
      return set
    },
    error(error: any) {
      errorHandler(error)
    },
    // prettier-ignore
    processFieldFunction(fieldValue: any, columnName: string, row?: any) { // eslint-disable-line
      // Formats expected objects as readable string(s)
      const column = columnName.toLowerCase().replace(/_/g, ' ')
      if (['created', 'date', 'updated'].includes(column)) {
        // Is date
        if('updated' === column) return processHumanReadableDate(fieldValue)
        return processHumanReadableDate(fieldValue, true)
      }
      if (column === 'child skus') {
        // Is Child SKUs field
        if (fieldValue) {
          const childSkuStringArray = fieldValue.map((child: SkuChildSkusInner) => {
            return (
              (this.relevantSKUs.find((sku: LocalSku) => sku.id === child.child_sku)?.label || 'SKU_Not_Found') +
              (child.count && child.count > 1 ? ' [×' + child.count + ']' : '')
            )
          })
          return fieldValue.length > 1 ? childSkuStringArray.join(', ') : childSkuStringArray[0]
        } else {
          return ''
        }
      }
      if (column === 'material') {
        // Is Material field
        fieldValue = fieldValue === null ? '' : fieldValue?.label ?? ''
      }
      if (column === 'design type') {
        // Is Design Type field
        designTypes.forEach((value) => {
          fieldValue = value.type === fieldValue ? value.label : fieldValue
        })
      }
      if (column === 'sku type') {
        // Is SKU Type field
        skuTypes.forEach((value) => {
          fieldValue = value.type === fieldValue ? value.label : fieldValue
        })
      }

      if (column === 'requires post processing') {
        return fieldValue === true || (fieldValue && fieldValue.toString().toLowerCase() === 'yes')
          ? {
              component: TableIconComponent,
              propObject: {
                iconComponent: CheckCircleIcon,
                tooltip: fieldValue,
                class: 'text-lime-500 inline-block w-6 h-6'
              }
            }
          : {
              component: TableIconComponent,
              propObject: {
                iconComponent: XCircleIcon,
                tooltip: fieldValue
              }
            }
      }

      return fieldValue
    },
    processColumnHeaderFunction(columnName: string) {
      if (stringLabelCompare(columnName, 'requires post processing')) return 'Post Processed'
      return columnName
    },
    async retrieveRelevantSkus(skuList: LocalSku[]): Promise<void> {
      const targetIDs: number[] = this.getRequiredSkuIDs(skuList)
      const newSkus: LocalSku[] = await api.getSkusIn(targetIDs)
      this.relevantSKUs = [...newSkus, ...skuList]
    },
    getRequiredSkuIDs(skuList: LocalSku[]): number[] {
      const skuListIDs: number[] = skuList.map((sku: LocalSku) => sku.id as number)
      const childSkusArray: SkuChildSkusInner[] = skuList.map((sku) => sku.child_skus).flat()
      const childIDs: number[] = childSkusArray.map((child) => child.child_sku as number)
      const uniqueCIDs: number[] = childIDs.filter((s, i, slf) => slf.indexOf(s) === i && s > -1)
      return uniqueCIDs.filter((i: number) => !skuListIDs.includes(i))
    }
  },
  created() {
    // Update Users Local Variables On Change
    const updateTable = () => this.updateKey++
    createUpdatedSkusListener(updateTable.bind(this))
  },
  components: {
    PageHeader,
    TableComponent,
    DocumentPlusIcon
  }
})
</script>

<template>
  <TransitionRoot as="template" :show="dialogOpen as boolean">
    <Dlg
      :id="contentTarget === 'viewErrorDialog' ? 'error-dialog-id' : 'dialog-id'"
      as="div"
      class="relative z-10"
      @click="cancel()"
      :inert="!isTopDialog ? 'inert' : undefined"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="dialog-container flex min-h-full justify-center text-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-90"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              data-testid="dialog-panel-test-id"
              @click="showToolTip(false)"
              class="overflow-visible dialog-content relative transform rounded-none sm:rounded-lg bg-white text-left shadow-xl transition-all"
            >
              <!-- Dialog Content -->
              <view-order-dialog-content
                v-if="contentTarget === DialogContent.ViewOrder"
                v-model:dialog-open="dialogOpen"
                :order-prop="contentObject"
                :editable="editable"
              />
              <view-item-dialog-content
                v-if="contentTarget === DialogContent.ViewItem"
                v-model:dialog-open="dialogOpen"
                :item-prop="contentObject"
                :editable="editable"
              />
              <view-item-manual-expansion-dialog-content
                v-if="contentTarget === DialogContent.ViewItemExpansion"
                v-model:dialog-open="dialogOpen"
                :item-prop="contentObject"
                :editable="editable"
              />
              <view-batch-sku-resolution-dialog-content
                v-if="contentTarget === DialogContent.ViewBatchSkuResolution"
                v-model:dialog-open="dialogOpen"
                :item-prop="contentObject"
              />
              <view-sku-dialog-content
                v-if="contentTarget === DialogContent.ViewSku"
                v-model:dialog-open="dialogOpen"
                :sku-prop="contentObject"
                :editable="editable"
              />
              <view-fault-dialog-content
                v-if="contentTarget === DialogContent.ViewFault"
                v-model:dialog-open="dialogOpen"
                :fault-prop="contentObject"
                :editable="editable"
              />
              <view-stock-check-dialog-content
                v-if="contentTarget === DialogContent.ViewStockCheck"
                v-model:dialog-open="dialogOpen"
                :stock-check-prop="contentObject"
                :editable="editable"
              />
              <error-dialog-content
                v-if="contentTarget === DialogContent.ViewError"
                v-model:dialog-open="dialogOpen"
                :error="contentObject as Error"
              />
              <confirm-dialog-content
                v-if="contentTarget === DialogContent.ViewConfirm"
                v-model:dialog-open="dialogOpen"
                :confirm-dialog-prop="contentObject"
                :cancel="canceled"
              />
              <confirm-number-dialog-content
                v-if="contentTarget === DialogContent.ViewConfirmNumber"
                v-model:dialog-open="dialogOpen"
                :confirm-dialog-prop="contentObject"
                :cancel="canceled"
              />
              <notification-dialog-content
                v-if="contentTarget === DialogContent.ViewNotification"
                :dialog-prop="contentObject"
                v-model:dialog-open="dialogOpen"
              />
              <confirm-cancel-status-count-dialog-content
                v-if="contentTarget === DialogContent.ViewConfirmCancelStatusCount"
                :dialog-prop="contentObject"
                v-model:dialog-open="dialogOpen"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dlg>
  </TransitionRoot>
</template>

<script lang="ts">
import { Dialog as Dlg, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import ViewOrderDialogContent from '@/modal_dialogs/ViewOrderDialogContent.vue'
import ViewItemDialogContent from '@/modal_dialogs/ViewItemDialogContent.vue'
import ViewSkuDialogContent from '@/modal_dialogs/ViewSkuDialogContent.vue'
import ViewFaultDialogContent from '@/modal_dialogs/ViewFaultDialogContent.vue'
import ErrorDialogContent from '@/modal_dialogs/ErrorDialogContent.vue'
import { createKeyDownListener, showToolTip } from '@/composables'
import ConfirmDialogContent from '@/modal_dialogs/ConfirmDialogContent.vue'
import { throwError } from '@/services/ErrorHandler'
import ViewStockCheckDialogContent from '@/modal_dialogs/ViewStockCheckDialogContent.vue'
import ViewBatchSkuResolutionDialogContent from '@/modal_dialogs/ViewBatchSkuResolutionDialogContent.vue'
import ViewItemManualExpansionDialogContent from '@/modal_dialogs/ViewItemManualExpansionDialogContent.vue'
import NotificationDialogContent from '@/modal_dialogs/NotificationDialogContent.vue'
import ConfirmNumberDialogContent from '@/modal_dialogs/ConfirmNumberDialogContent.vue'
import ConfirmCancelStatusCountDialogContent from '@/modal_dialogs/ConfirmCancelStatusCountDialogContent.vue'
import { DialogContent } from '@/modal_dialogs/dialog-helpers.ts'

export default defineComponent({
  name: 'DialogBase',
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    contentTarget: {
      type: String,
      required: true
    },
    contentObject: {
      type: Object as PropType<object>,
      required: true
    },
    closeOnBackgroundClick: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    isTopDialog: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogOpen: ref<boolean>(this.openDialog || false),
      canceled: false as boolean,
      DialogContent: DialogContent
    }
  },
  methods: {
    showToolTip,
    escapeEvent(e: Event) {
      if ((e as KeyboardEvent).key == 'Escape') {
        this.dialogOpen = false
      }
    },
    cancel() {
      if (this.closeOnBackgroundClick) {
        if (this.contentTarget === 'viewConfirmDialog') {
          this.canceled = true // Allow the Confirm Dialog to return a value (false)
        } else {
          this.dialogOpen = false // Close Dialog
        }
      }
    }
  },
  created() {
    createKeyDownListener(this.escapeEvent.bind(this))

    if (!this.contentTarget || this.contentTarget.length < 1) {
      throwError('Dialog Error', 'No contentTarget set for Dialog')
    }
  },
  watch: {
    dialogOpen: {
      // Transmits dialogOpen value to v-model:dialog-open="dialogOpen"
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(open) {
        // handler(newValue, OldValue)
        if (open === false) {
          this.$emit('update:openDialog', false)
        }
      }
    },
    openDialog: {
      immediate: true,
      handler(newValue) {
        this.dialogOpen = newValue
      }
    },
    updateContentObject: {
      immediate: true,
      handler(newValue) {
        this.$emit('update:contentObject', newValue)
      }
    }
  },
  components: {
    ConfirmCancelStatusCountDialogContent,
    NotificationDialogContent,
    ViewItemManualExpansionDialogContent,
    ViewBatchSkuResolutionDialogContent,
    ViewStockCheckDialogContent,
    ViewFaultDialogContent,
    ConfirmDialogContent,
    ConfirmNumberDialogContent,
    ErrorDialogContent,
    Dlg,
    DialogPanel,
    TransitionRoot,
    TransitionChild,
    ViewOrderDialogContent,
    ViewItemDialogContent,
    ViewSkuDialogContent
  }
})
</script>

<style scoped>
.dialog-container {
  padding: 0;
  align-items: normal;
  min-height: 0;
  margin: 0;
  height: 100vh;
  height: 100dvh;
}
.dialog-content {
  min-width: 275px;
  max-width: none;
  width: 100%;
  align-items: center;
  min-height: 100%;
  height: 100%;
  max-height: none;
  grid-template-rows: 1fr;
  display: grid;
  bottom: 0;
}

@media (min-width: 640px) {
  .dialog-container {
    min-width: fit-content;
    height: auto;
    align-items: center;
    min-height: 100%;
  }

  .dialog-content {
    max-height: max(40rem, min(calc(100vh - 4rem), 60rem));
    max-height: max(40rem, min(calc(100dvh - 4rem), 60rem));
    max-width: 40rem;
    width: fit-content;
    height: auto;
    min-height: 0;
  }
}
</style>

<template>
  <dialog-content
    :dialog-title="dialogTitle"
    :on-submit="saveItems"
    :buttons="[
      { label: 'Cancel', onClick: close },
      { label: 'Add New SKU', onClick: openNewSkuDialog },
      {
        label: 'Save and Mark Resolved',
        type: 'submit',
        loading: loading
      }
    ]"
  >
    <div class="inline-block max-w-md pb-4 text-center text-gray-700 text-sm font-medium leading-6">
      <p>Please assign the correct SKU for the following items.</p>
    </div>

    <text-input v-if="itemIDs.length > 0" :label="'Item IDs'" :text="itemIDs" :editable="false" />

    <!-- Parent Order Information For Reference: -->
    <text-input
      v-if="sourceOrderIDs.length > 0"
      :label="'Parent Order IDs'"
      :text="sourceOrderIDs"
      :editable="false"
    />
    <text-input
      v-if="customerNames.length > 0"
      :label="'Customer Names'"
      :text="customerNames"
      :editable="false"
    />
    <text-input
      v-if="orderSourcesText.length > 0"
      :label="'Order Sources'"
      :text="orderSourcesText"
      :editable="false"
    />

    <!-- Erroneous SKU: -->
    <text-input
      :label="'Erroneous SKUs'"
      :text="erroneousSkus.length > 0 ? erroneousSkus : '( SKUs Not Set )'"
      :editable="false"
      :input-style-override="
        'font-style: italic;' +
        (erroneousSkus.length > 0 ? ' color: #b91c1c;' : ' opacity: 0.5; font-style: italic;')
      "
    />

    <!--  Notes: -->
    <text-field-input
      v-if="itemNotes.length > 0"
      :label="'Item Notes'"
      :editable="false"
      :text="itemNotes"
    />

    <!--  SKU: -->
    <list-input
      :update-key="updateKey"
      id="itemSkuDropDown"
      class="w-full"
      :editable="true"
      :label="'Select Correct SKU:'"
      :options="getSkusForDropDown"
      :selected="selectedSku"
      @update:selected="selectedSku = $event as LocalSku"
      :object-label-key-name="'label'"
      :custom-label-get-function="customLabelGetFunction"
      :max-height="'17rem'"
    />
  </dialog-content>
</template>

<script lang="ts">
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import {
  createNewItemSkuListener,
  createUpdatedSkusListener,
  itemsUpdated,
  showSpinner
} from '@/composables'
import {
  LocalOrderItem,
  LocalOrder,
  LocalSku,
  type BatchSkuResolutionDialogObject,
  getSkusForDropDown,
  api,
  LocalOrderImportSource,
  getInitialSku,
  LocalOrderItemStatusEnum
} from '@/services/DataServices'
import { type Sku } from '@/api'
import { throwError } from '@/services/ErrorHandler'
import TextInput from '@/components/inputComponents/TextInput.vue'
import ListInput from '@/components/inputComponents/ListInput.vue'
import { setGlobalUpdatingFlag } from '@/main'
import TextFieldInput from '@/components/inputComponents/TextFieldInput.vue'
import { confirmDialog, newSkuDialog } from '@/modal_dialogs/dialog-helpers.ts'

export default defineComponent({
  name: 'ViewBatchSkuResolutionDialogContent',
  emits: ['update:dialogOpen'],
  components: {
    TextFieldInput,
    ListInput,
    TextInput,
    DialogContent
  },
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
      default: true
    },
    itemProp: {
      type: Object as PropType<object | null>,
      required: true
    }
  },
  computed: {
    dialogTitle() {
      return 'SKU Resolution for ' + this.dialogItems.length + ' items.'
    }
  },
  data() {
    return {
      updateKey: 0 as number,
      dialogItems: [] as LocalOrderItem[],
      customerNames: '' as string,
      orderSourcesText: '' as string,
      erroneousSkus: '' as string,
      sourceOrderIDs: '' as string,
      itemIDs: '' as string,
      itemNotes: '' as string,
      selectedSku: null as LocalSku | null,
      loading: false as boolean,
      itemIdAwaitingNewSKU: undefined as number | undefined
    }
  },
  methods: {
    getSkusForDropDown,
    close() {
      this.$emit('update:dialogOpen', false)
    },
    closeDialog() {
      itemsUpdated()
      this.close()
    },
    openNewSkuDialog() {
      newSkuDialog({} as LocalSku, this.itemIdAwaitingNewSKU)
    },
    customLabelGetFunction: (value: Sku) => {
      return value.description ? value.label + '  -  ' + value.description : value.label
    },
    async saveItems() {
      if (
        await confirmDialog(
          'Set Sku For ' + this.dialogItems.length + ' items?',
          'Are you sure you want to set SKU "' +
            (this.selectedSku?.label || this.selectedSku?.id || '') +
            '" for these ' +
            this.dialogItems.length +
            ' items?'
        )
      ) {
        showSpinner(true, this.dialogItems.length)
        setGlobalUpdatingFlag(true)
        for (const item of this.dialogItems) {
          await this.saveItem(item)
        }
        setGlobalUpdatingFlag(false)
        showSpinner(false)
        this.closeDialog()
      }
    },
    async saveItem(item: LocalOrderItem) {
      const sku: LocalSku | null = this.selectedSku
      await this.runSkuResolutionForItem(item)
      item.sku = sku?.id
      item.unresolved_sku = null
      item.status = LocalOrderItemStatusEnum.ReviewBlocked
    },
    async runSkuResolutionForItem(item: LocalOrderItem) {
      item.unresolved_sku = (await api.getSku(this.selectedSku?.id || -1))?.label
      await item.updateWithResolvedSku()
    },
    async processItemOrders() {
      this.loading = true
      try {
        const itemProp = this.itemProp as BatchSkuResolutionDialogObject
        this.dialogItems = itemProp.orderItems || []
        this.selectedSku = await getInitialSku()
        if (this.dialogItems.some((item) => !item.hasStatusSkuResolutionFailed)) {
          throwError(
            'Error in SKU Resolution Dialog props.',
            'Item status is not SKU_RESOLUTION_FAILED.'
          )
          this.closeDialog()
          return
        }
        const parents: Array<number | undefined> = this.dialogItems.map((item) => item.order)
        const parentIDs: number[] = parents.filter((id) => id !== undefined) as number[]
        const uniqueParentIDs: number[] = [...new Set(parentIDs.map((id) => id))]
        const parentOrders: LocalOrder[] = await api.getOrdersIn(uniqueParentIDs)

        this.dialogItems?.forEach((item: LocalOrderItem) => {
          const itemParentOrder = parentOrders.find((o) => o.id === item.order)
          const OrderSource: LocalOrderImportSource | undefined =
            itemParentOrder?.order_import_source
          if (item.id && !this.itemIDs.includes(item.id.toString())) {
            this.itemIDs += item.id + ', '
          }
          if (itemParentOrder && !this.customerNames.includes(itemParentOrder.customer_name)) {
            this.customerNames += itemParentOrder.customer_name + ', '
          }
          if (
            itemParentOrder &&
            itemParentOrder?.id &&
            !this.sourceOrderIDs.includes(itemParentOrder.id.toString())
          ) {
            this.sourceOrderIDs += itemParentOrder.id + ', '
          }
          if (OrderSource?.name && !this.orderSourcesText.includes(OrderSource.name)) {
            this.orderSourcesText += OrderSource.name + ', '
          }
          if (!item.unresolved_sku || !this.erroneousSkus?.includes(item.unresolved_sku)) {
            this.erroneousSkus +=
              '"' +
              (item.unresolved_sku && item.unresolved_sku.length > 0
                ? item.unresolved_sku
                : '( SKU Not Set )') +
              '", '
          }
          if (item.notes && !this.itemNotes.includes(item.notes)) {
            const notes = item.notes.replace(/(\r\n|\n|\r)/gm, '')
            this.itemNotes += '"' + notes + '",\n'
          }
        })

        this.customerNames = this.customerNames.length > 0 ? this.customerNames.slice(0, -2) : ''
        this.orderSourcesText =
          this.orderSourcesText.length > 0 ? this.orderSourcesText.slice(0, -2) : ''
        this.sourceOrderIDs = this.sourceOrderIDs.length > 0 ? this.sourceOrderIDs.slice(0, -2) : ''
        this.erroneousSkus = this.erroneousSkus.length > 0 ? this.erroneousSkus.slice(0, -2) : ''
        this.itemNotes = this.itemNotes.length > 0 ? this.itemNotes.slice(0, -2) : ''
        this.itemIDs = this.itemIDs.length > 0 ? this.itemIDs.slice(0, -2) : ''
      } catch (e) {
        throwError('Error Processing Item Orders.', e)
        this.closeDialog()
      }

      this.loading = false
    }
  },
  created() {
    try {
      this.processItemOrders()
      const updateList = () => this.updateKey++
      createUpdatedSkusListener(updateList.bind(this))
      const updateItemSKU: Function = (sku: LocalSku) => (this.selectedSku = sku)
      createNewItemSkuListener(this.itemIdAwaitingNewSKU, updateItemSKU.bind(this))
    } catch (e) {
      throwError('Error in ItemDialog props.', e)
      this.closeDialog()
    }
  }
})
</script>

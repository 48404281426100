<template>
  <dialog-content
    :dialog-title="dialogTitle"
    :on-submit="expandItems"
    :buttons="[
      { label: 'Cancel', onClick: closeDialog },
      {
        label: 'Create New SKU',
        onClick: openNewSkuDialog
      },
      {
        label: 'Expand',
        onClick: expandItems,
        tooltip: 'Expand Order Item Into Multiple New Order Items',
        loading: loading
      }
    ]"
  >
    <!-- Erroneous SKU: -->
    <text-input
      :label="'Erroneous SKU:'"
      :text="
        '&quot;' +
        ((dialogItem?.unresolved_sku || '').length > 0
          ? dialogItem.unresolved_sku
          : '( SKU Not Set )') +
        '&quot;'
      "
      :editable="false"
      :input-style-override="
        'font-style: italic;' +
        ((dialogItem?.unresolved_sku || '').length > 0
          ? ' color: #b91c1c;'
          : ' opacity: 0.5; font-style: italic;')
      "
    />

    <!--  Notes: -->
    <text-field-input
      v-if="dialogItem?.notes && dialogItem.notes.length > 0"
      :label="'Item Notes'"
      v-model:text="dialogItem.notes"
      :editable="false"
    />

    <!-- New SKUs: -->
    <div class="pt-4">
      <div class="flex items-center">
        <h2 class="flex px-4 mx-auto text-base font-semibold leading-7 text-gray-900">
          New Item SKUs:
        </h2>
      </div>
      <div class="flex items-center">
        <h2 class="flex px-4 mx-auto text-sm leading-7 text-gray-600 pb-4">
          {{ toBeCreatedMessage }}
        </h2>
      </div>

      <div class="w-full flex justify-center pb-4">
        <button
          type="button"
          v-tooltip="'Add New Item'"
          class="cursor-pointer sm:col-span-2 p-2 sm:p-1 flex w-12 h-12 sm:h-8 sm:w-8 text-gray-600 hover:text-gray-950 hover:bg-slate-600/[0.1] rounded-3xl shadow-[1px_1px_4px_0_rgba(0,0,0,0.4)]"
          @click="addChildSku()"
        >
          <document-plus-icon
            style="padding-left: 1px; padding-bottom: 1px"
            class="h-full w-full"
            aria-hidden="true"
          />
        </button>
      </div>

      <div
        v-for="(sku, index) in dialogNewSkuObjects.slice().reverse() as DialogChildOrderItemSku[]"
        :key="dialogNewSkuObjects.length - index - 1"
      >
        <div class="flex gap-1 sm:gap-4 w-full items-center">
          <button
            type="button"
            v-tooltip="'Remove Child SKU'"
            class="min-w-[2rem] min-h-[2rem] cursor-pointer self-center p-0 sm:p-1 flex w-8 h-8 text-gray-600 rounded-3xl hover:text-gray-950 hover:bg-slate-600/[0.1]"
            @click="removeChildSku(sku)"
          >
            <trash-icon
              style="padding-left: 1px; padding-bottom: 1px"
              class="h-full w-full"
              aria-hidden="true"
            />
          </button>
          <list-input
            :update-key="updateKey"
            id="itemSkuDropDown"
            class="w-full"
            :editable="true"
            :options="getSkusForDropDown"
            :selected="sku.child_sku || null"
            @update:selected="sku.child_sku = $event as LocalSku"
            :object-label-key-name="'label'"
            :custom-label-get-function="customLabelGetFunction"
            :max-height="'17rem'"
          />
          <div class="py-0.5 sm:py-0 flex items-end mb-[0.1rem]">
            <span class="text-gray-900 flex text-center sm:self-auto w-4 sm:w-10 h-8">×</span>
            <number-input
              :min="1"
              v-model:number="sku.count"
              :editable="true"
              :input-style-override="'width: clamp(3rem, 15dvw, 7rem);'"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="inline-block max-w-md p-4 pt-6 text-center text-gray-600 text-sm font-medium leading-6"
    >
      <p>
        Item {{ dialogItem.id }} will be expanded into the above new Order Items and then marked as
        COMPLETE.
      </p>
    </div>
  </dialog-content>
</template>

<script lang="ts">
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { createUpdatedSkusListener, itemsUpdated, itemUpdated, showToolTip } from '@/composables'
import {
  getInitialSku,
  getSkusForDropDown,
  LocalOrder,
  LocalOrderItem,
  LocalSku,
  type ManualExpansionDialogObject
} from '@/services/DataServices'
import { type Sku } from '@/api'
import { throwError } from '@/services/ErrorHandler'
import TextInput from '@/components/inputComponents/TextInput.vue'
import { DocumentPlusIcon, TrashIcon } from '@heroicons/vue/24/outline'
import ListInput from '@/components/inputComponents/ListInput.vue'
import TextFieldInput from '@/components/inputComponents/TextFieldInput.vue'
import NumberInput from '@/components/inputComponents/NumberInput.vue'
import { newErrorDialog, newSkuDialog } from '@/modal_dialogs/dialog-helpers.ts'

type DialogChildOrderItemSku = {
  child_sku: LocalSku | Sku
  count?: number
}

export default defineComponent({
  name: 'ViewItemManualExpansionDialogContent',
  emits: ['update:dialogOpen'],
  computed: {
    toBeCreatedMessage() {
      let countCountsTotal = 0
      this.dialogNewSkuObjects?.forEach((skuObject: DialogChildOrderItemSku) => {
        countCountsTotal = countCountsTotal + (skuObject.count || 1)
      })
      const numItems: string = countCountsTotal > 1 ? countCountsTotal + ' Items' : '1 Item'
      const orderId: string = this.dialogParentOrder?.id ? ' ' + this.dialogParentOrder?.id : ''
      return numItems + ' to be created and added to Order' + orderId
    },
    dialogTitle() {
      return 'Expand Item ' + this.dialogItem.id + ' Into New Order Items'
    }
  },
  components: {
    NumberInput,
    TextFieldInput,
    ListInput,
    TrashIcon,
    DocumentPlusIcon,
    TextInput,
    DialogContent
  },
  props: {
    dialogOpen: {
      // Receives dialogOpen value from v-model:dialog-open="dialogOpen"
      type: Boolean,
      required: true,
      default: true
    },
    itemProp: {
      type: Object as PropType<object | null>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    editableFields: {
      type: Array as PropType<string[] | null>,
      default: () => ['notes', 'custom_text', 'post_processing_notes']
    }
  },
  data() {
    return {
      updateKey: 0 as number,
      dialogItem: {} as LocalOrderItem,
      dialogNewSkuObjects: [] as DialogChildOrderItemSku[],
      initialSku: {} as LocalSku,
      dialogParentOrder: undefined as LocalOrder | undefined,
      customLabelGetFunction: (value: Sku) => {
        return value.description ? value.label + '  -  ' + value.description : value.label
      },
      loading: false as boolean
    }
  },
  methods: {
    getSkusForDropDown,
    addChildSku(sku?: DialogChildOrderItemSku) {
      if (sku) {
        this.dialogNewSkuObjects.push(sku)
      } else if (this.initialSku && JSON.stringify(this.initialSku) !== JSON.stringify({})) {
        this.dialogNewSkuObjects.push({ child_sku: this.initialSku, count: 1 })
      } else {
        newErrorDialog(
          'Item Expansion Error',
          'No SKUs available in the system, at-least one SKU must be added first.'
        )
      }
    },
    removeChildSku(sku: DialogChildOrderItemSku) {
      showToolTip(false)
      if (this.dialogNewSkuObjects.length === 1) {
        newErrorDialog('Item Expansion Error', 'At-least one SKU must be selected.')
        return
      }
      this.dialogNewSkuObjects.splice(this.dialogNewSkuObjects.indexOf(sku), 1)
    },
    closeDialog(updatedItem?: LocalOrderItem) {
      // Updates Users Local Items Object
      if (updatedItem) {
        itemUpdated(updatedItem)
        itemsUpdated()
      }
      // Transmits dialogOpen value to <view-item-dialog-content v-model:dialog-open="dialogOpen" />
      this.$emit('update:dialogOpen', false)
    },
    expandItems() {
      this.dialogItem.manuallyExpand(this.dialogNewSkuObjects)
      this.closeDialog(this.dialogItem)
    },
    openNewSkuDialog() {
      newSkuDialog({} as LocalSku)
    },
    async initialize() {
      this.loading = true
      this.initialSku = await getInitialSku()
      this.dialogNewSkuObjects = [{ child_sku: this.initialSku, count: 1 }]
      this.loading = false
    }
  },
  created() {
    try {
      const itemProp: ManualExpansionDialogObject = this.itemProp as ManualExpansionDialogObject
      if (!itemProp.orderItem || !itemProp.orderItem.hasStatusSkuResolutionFailed) {
        throwError(
          'Invalid Dialog Data',
          'An Invalid Object was provided to the Manual Expansion Dialog.'
        )
        this.closeDialog()
        return
      }
      this.dialogItem = new LocalOrderItem(itemProp.orderItem as LocalOrderItem)
      this.dialogParentOrder = itemProp.order
      this.initialize()
      const updateList = () => this.updateKey++
      createUpdatedSkusListener(updateList.bind(this))
    } catch (e) {
      throwError('Error in Manual Expansion Dialog props.', e)
      this.closeDialog()
    }
  }
})
</script>

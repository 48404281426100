<script setup lang="ts">
import NavBar from '@/components/NavBar.vue'
import DialogBase from '@/modal_dialogs/DialogBase.vue'
import { reactive, ref } from 'vue'
import {
  createLoadingItemsUpdatedListener,
  createShowSpinnerListener,
  createToolTipListener
} from '@/composables'
import SpinnerComponent from '@/components/SpinnerComponent.vue'
import ToolTipComponent from '@/components/ToolTipComponent.vue'
import csawfApi from '@/csawf-api'
import { createDialogListener } from '@/modal_dialogs/dialog-helpers.ts'

const navigation = [
  { name: 'Orders', href: '/orders' },
  { name: 'Items', href: '/items' },
  { name: 'SKUs', href: '/skus' },
  { name: 'Faults', href: '/faults' }
]
const userNavigation = [
  { name: 'Admin', href: csawfApi.getBackendAdminUrl(), external: true },
  { name: 'Sign out', href: '/logout', external: false }
]

type Dialog = {
  dialogIsOpen: boolean
  dialogContentTarget: string
  dialogContent: object
  closeOnBackgroundClick: boolean
  dialogContentEditable: boolean
  isTopDialog: boolean
  id: number
}

const dialogs = reactive<Array<Dialog>>([])

const removeDialog: Function = (open: boolean, dialog: Dialog) => {
  // Remove dialog object after transition
  if (open === false) {
    dialogs[dialogs.indexOf(dialog)].isTopDialog = false
    dialogs[dialogs.indexOf(dialog)].dialogIsOpen = false
    const lastDialog: Dialog | undefined = dialogs[dialogs.indexOf(dialog) - 1]
    if (lastDialog) lastDialog.isTopDialog = true
    setTimeout(() => dialogs.splice(dialogs.indexOf(dialog), 1), 400)
  }
}

let toolTipText: string = ''
const showToolTip = ref<boolean>(false)
const showSpinner = ref<boolean>(false)
const loadingSpinnerItemsToUpdate = ref<number>(0)
const loadingSpinnerItemsUpdated = ref<number>(0)

createToolTipListener((event: any) => {
  showToolTip.value = event.detail.show
  toolTipText = event.detail.text
})

createShowSpinnerListener((event: any) => {
  loadingSpinnerItemsUpdated.value = 0
  showSpinner.value = (event?.detail?.showSpinner || false) as boolean
  loadingSpinnerItemsToUpdate.value = event?.detail?.totalNumberOfUpdates || 0
})

createLoadingItemsUpdatedListener(() => {
  loadingSpinnerItemsUpdated.value++
})

createDialogListener((event: any) => {
  if (!event.detail) {
    // If no data is passed, clear dialogs and return
    dialogs.splice(0)
    return
  } else if (typeof event.detail === 'number') {
    // Close specific dialog id
    dialogs.forEach((dialog) => {
      if (dialog.id === event.detail) {
        removeDialog(false, dialog)
      }
    })
  } else if (
    !(
      event.detail.dialogContent &&
      dialogs.some(
        (dialog) =>
          JSON.stringify(dialog.dialogContent) === JSON.stringify(event.detail.dialogContent)
      )
    )
  ) {
    const lastDialog: Dialog | undefined = dialogs[dialogs.length - 1]
    if (lastDialog) lastDialog.isTopDialog = false
    // If no duplicate identical dialog is open, add it to the list
    dialogs.push({
      dialogContentTarget: event.detail.dialogTarget,
      dialogContent: event.detail.dialogContent,
      dialogIsOpen: true,
      closeOnBackgroundClick:
        event.detail.closeOnBackgroundClick === undefined
          ? true
          : event.detail.closeOnBackgroundClick,
      dialogContentEditable: event.detail.editable || false,
      isTopDialog: true,
      id: event.detail.id || Date.now()
    })
  }
})
</script>

<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-100">
    <body class="h-full">
    ```
  -->
  <spinner-component
    :show="showSpinner"
    :items-to-update="loadingSpinnerItemsToUpdate"
    :items-updated="loadingSpinnerItemsUpdated"
  />
  <div class="full-screen-container">
    <tool-tip-component :show="showToolTip" :text="toolTipText" />
    <nav-bar
      id="navbar"
      v-show="$route.name && $route.name !== 'login'"
      :user="csawfApi.getUser()"
      :navigation="navigation"
      :userNavigation="userNavigation"
    />
    <dialog-base
      v-for="dialog in dialogs"
      :key="dialog.id"
      :content-target="dialog.dialogContentTarget"
      :open-dialog="dialog.dialogIsOpen"
      @update:open-dialog="removeDialog($event, dialog)"
      :content-object="dialog.dialogContent"
      :close-on-background-click="dialog.closeOnBackgroundClick"
      :editable="dialog.dialogContentEditable"
      :is-top-dialog="dialog.isTopDialog"
    />
    <router-view v-slot="{ Component }">
      <!--  Cached components, Un-cached when returning to login route -->
      <keep-alive v-if="$route.name !== 'login'">
        <component :is="Component" />
      </keep-alive>
      <!--  Un-cached LoginView -->
      <component v-if="$route.name === 'login'" :is="Component" />
    </router-view>
  </div>
</template>

import {
  type LocalOrderItem,
  type LocalOrderItemStatusCount,
  type LocalSku,
  type CancelItemCountReturnType,
  type ItemDialogObject,
  type OrderDialogObject,
  type StockCheckDialogObject,
  type BatchStockCheckDialogObject,
  type FaultDialogObject,
  type BatchSkuResolutionDialogObject,
  type ManualExpansionDialogObject,
  type SkuDialogObject,
  newBlankOrder
} from '@/services/DataServices'
import type { MessageType } from '@/components/AlertMessageComponent.vue'
import { addListener, globalEvent, removeListener } from '@/composables.ts'

export enum DialogContent {
  ViewOrder = 'viewOrderDialog',
  ViewItem = 'viewItemDialog',
  ViewSku = 'viewSkuDialog',
  ViewFault = 'viewFaultDialog',
  ViewError = 'viewErrorDialog',
  ViewStockCheck = 'viewStockCheckDialog',
  ViewBatchSkuResolution = 'viewBatchSkuResolutionDialog',
  ViewItemExpansion = 'viewItemExpansionDialog',
  ViewNotification = 'viewNotificationDialog',
  ViewConfirm = 'viewConfirmDialog',
  ViewConfirmNumber = 'viewConfirmNumberDialog',
  ViewConfirmCancelStatusCount = 'viewConfirmCancelStatusCountDialogContent'
}

export function newDialog(
  targetDialog: string,
  dialogContent: any,
  editable: boolean = false,
  closeOnBackgroundClick?: boolean,
  id: number = Date.now()
): void {
  // Create and emit new global event to open a new dialog from App.vue
  globalEvent('globalAppDialogEvent', {
    dialogTarget: targetDialog,
    dialogContent: dialogContent,
    editable: editable,
    closeOnBackgroundClick: closeOnBackgroundClick != null ? closeOnBackgroundClick : !editable,
    id: id
  })
}

export function closeDialog(id: number): void {
  globalEvent('globalAppDialogEvent', id)
}

export function closeAllDialogs(): void {
  globalEvent('globalAppDialogEvent', undefined)
}

export function newSkuDialog(
  sku: LocalSku,
  itemIdAwaitingNewSKU?: number,
  editable: boolean = true,
  closeOnBackgroundClick: boolean = false
): void {
  const dialogContent: SkuDialogObject = { sku: sku, itemIdAwaitingNewSKU: itemIdAwaitingNewSKU }
  newDialog(DialogContent.ViewSku, dialogContent, editable, closeOnBackgroundClick)
}

export function newViewItemDialog(
  dialogContent: ItemDialogObject,
  editable: boolean = false,
  closeOnBackgroundClick?: boolean
): void {
  newDialog(DialogContent.ViewItem, dialogContent, editable, closeOnBackgroundClick)
}

export function newViewOrderDialog(
  dialogContent: OrderDialogObject,
  editable: boolean = false,
  closeOnBackgroundClick?: boolean
): void {
  newDialog(DialogContent.ViewOrder, dialogContent, editable, closeOnBackgroundClick)
}

export function newNewOrderDialog(
  editable: boolean = false,
  closeOnBackgroundClick?: boolean
): void {
  newDialog(DialogContent.ViewOrder, newBlankOrder(), editable, closeOnBackgroundClick)
}

export function newViewStockCheckDialog(
  dialogContent: StockCheckDialogObject,
  editable: boolean = false,
  closeOnBackgroundClick?: boolean
): void {
  newDialog(DialogContent.ViewStockCheck, dialogContent, editable, closeOnBackgroundClick)
}

export function newViewBatchStockCheckDialog(
  dialogContent: BatchStockCheckDialogObject,
  editable: boolean = false,
  closeOnBackgroundClick?: boolean
): void {
  newDialog(DialogContent.ViewStockCheck, dialogContent, editable, closeOnBackgroundClick)
}

export function newViewFaultDialog(
  dialogContent: FaultDialogObject,
  editable: boolean = false,
  closeOnBackgroundClick?: boolean
): void {
  newDialog(DialogContent.ViewFault, dialogContent, editable, closeOnBackgroundClick)
}

export function newViewBatchSkuResolutionDialog(
  dialogContent: BatchSkuResolutionDialogObject,
  editable: boolean = false,
  closeOnBackgroundClick?: boolean
): void {
  newDialog(DialogContent.ViewBatchSkuResolution, dialogContent, editable, closeOnBackgroundClick)
}

export function newViewItemExpansionDialog(
  dialogContent: ManualExpansionDialogObject,
  editable: boolean = false,
  closeOnBackgroundClick?: boolean
): void {
  newDialog(DialogContent.ViewItemExpansion, dialogContent, editable, closeOnBackgroundClick)
}

export async function confirmDialog(
  label: string,
  message: string,
  alert?: { message: string; messageType?: MessageType }
): Promise<boolean> {
  return new Promise((resolve) => {
    const ConfirmationDialogId: number = Date.now()
    const listener: EventListener = (event: any) => {
      removeListener(listener, 'confirmDialogClosed')
      resolve(event.detail.confirmed)
    }
    newDialog(
      DialogContent.ViewConfirm,
      { label: label, message: message, alert: alert },
      false,
      true,
      ConfirmationDialogId
    )
    addListener(listener, 'confirmDialogClosed')
  })
}

export async function confirmNumberDialog(
  label: string,
  message: string,
  maxCount?: number
): Promise<{ confirmed: boolean; count: number }> {
  return new Promise((resolve) => {
    const ConfirmationDialogId: number = Date.now()
    const listener: EventListener = (event: any) => {
      removeListener(listener, 'confirmNumberDialogClosed')
      resolve({ confirmed: event.detail.confirmed, count: event.detail.count })
    }
    newDialog(
      DialogContent.ViewConfirmNumber,
      { label: label, message: message, maxCount: maxCount },
      false,
      true,
      ConfirmationDialogId
    )
    addListener(listener, 'confirmNumberDialogClosed')
  })
}

export async function confirmCancelStatusCountDialog(
  item: LocalOrderItem,
  message?: string,
  alert?: { message: string; messageType?: MessageType }
): Promise<CancelItemCountReturnType> {
  return new Promise((resolve) => {
    const ConfirmationDialogId: number = Date.now()
    const listener: EventListener = (event: any) => {
      removeListener(listener, 'confirmCancelStatusCountDialogClosed')
      resolve({
        confirmed: event.detail.confirmed,
        cancelEntireItem: event.detail.cancelEntireItem,
        statusCount: event.detail.statusCount
      })
    }
    newDialog(
      DialogContent.ViewConfirmCancelStatusCount,
      { item: item, message: message, alert: alert },
      false,
      true,
      ConfirmationDialogId
    )
    addListener(listener, 'confirmCancelStatusCountDialogClosed')
  })
}

export function confirmDialogClose(confirmed: boolean): void {
  // Create and emit new global event for confirmation dialog result
  globalEvent('confirmDialogClosed', { confirmed: confirmed })
}

export function confirmNumberDialogClose(confirmed: boolean, count?: number): void {
  globalEvent('confirmNumberDialogClosed', { confirmed: confirmed, count: count })
}

export function confirmCancelStatusCountDialogClosed(
  confirmed: boolean,
  cancelEntireItem: boolean,
  statusCount: LocalOrderItemStatusCount | undefined
): void {
  globalEvent('confirmCancelStatusCountDialogClosed', {
    confirmed: confirmed,
    cancelEntireItem: cancelEntireItem,
    statusCount: statusCount
  })
}

export function newErrorDialog(errorName: string, errorMessage?: String, then?: Function): void {
  newDialog(
    DialogContent.ViewError,
    { name: errorName, message: errorMessage, then: then },
    false,
    false
  )
}

export function newNotificationDialog(message: string, title?: string): void {
  newDialog(DialogContent.ViewNotification, { title: title || '', message: message }, false, true)
}

export function createDialogListener(targetFunction: EventListener): void {
  // Create Listener for dialog creation events (used in App.vue)
  addListener(targetFunction, 'globalAppDialogEvent')
}

import { nextTick, onMounted, onUnmounted } from 'vue'
import type { LocalOrder, LocalOrderItem, LocalSku } from '@/services/DataServices'
// Composables (Stateful logic management functions)
// https://vuejs.org/guide/reusability/composables.html#mouse-tracker-example
// Not 100% sure though

function addComponentScopeEventListener(
  targetFunction: EventListenerOrEventListenerObject,
  eventName: string
): void {
  // Create & remove event listeners for dialog key word
  addListener(targetFunction, eventName)
  onUnmounted(() => removeListener(targetFunction, eventName))
}

export function addListener(
  targetFunction: EventListenerOrEventListenerObject,
  eventName: string
): void {
  window.addEventListener(eventName, targetFunction)
}

export function removeListener(
  targetFunction: EventListenerOrEventListenerObject,
  eventName: string
): void {
  window.removeEventListener(eventName, targetFunction)
}

function createGlobalDocumentListener(targetFunction: EventListener, eventName: string): void {
  // Create & remove event listeners for dialog key word
  onMounted(() => document.addEventListener(eventName, targetFunction, true))
  onUnmounted(() => document.removeEventListener(eventName, targetFunction, true))
}

export function globalEvent(eventName: string, eventDetail: any): void {
  // Create and emit new global event to open a new dialog from App.vue
  dispatchEvent(
    new CustomEvent(eventName, {
      detail: eventDetail,
      bubbles: true
    })
  )
}

export function createCapsLockListener(targetFunction: (active: boolean) => void): void {
  const callbackFunction = (e: Event): void => {
    const capslockIsActive: boolean =
      (e as KeyboardEvent)?.getModifierState && (e as KeyboardEvent)?.getModifierState('CapsLock')
    targetFunction(capslockIsActive)
  }
  addComponentScopeEventListener(callbackFunction, 'keydown')
}

export function createResizeListener(targetFunction: EventListener): void {
  addComponentScopeEventListener(targetFunction, 'resize')
}

export function createGlobalDocumentScrollListener(targetFunction: EventListener): void {
  createGlobalDocumentListener(targetFunction, 'scroll')
}

export function createScrollListener(targetFunction: EventListener): void {
  addComponentScopeEventListener(targetFunction, 'scroll')
}

export function createGlobalDocumentScrollWheelListener(targetFunction: EventListener): void {
  createGlobalDocumentListener(targetFunction, 'wheel')
}

export function createScrollWheelListener(targetFunction: EventListener): void {
  addComponentScopeEventListener(targetFunction, 'wheel')
}

export function createKeyDownListener(targetFunction: EventListener): void {
  addComponentScopeEventListener(targetFunction, 'keydown')
}

export function createShowSpinnerListener(targetFunction: EventListener): void {
  addComponentScopeEventListener(targetFunction, 'showGlobalLoadingSpinner')
}

export function createSpinnerMessageListener(targetFunction: EventListener): void {
  addComponentScopeEventListener(targetFunction, 'setGlobalLoadingSpinnerMessage')
}

export function setSpinnerMessage(message: string): void {
  globalEvent('setGlobalLoadingSpinnerMessage', { message: message })
}

export function showSpinner(show: boolean, totalNumberOfUpdates?: number): void {
  // Create and emit new global event to open a new dialog from App.vue
  globalEvent('showGlobalLoadingSpinner', {
    showSpinner: show,
    totalNumberOfUpdates: totalNumberOfUpdates
  })
}

export async function runFunctionWithLoadingSpinner<T>(
  functionToRun: () => Promise<T>,
  numberOfUpdateEvents?: number
): Promise<T> {
  showSpinner(true, numberOfUpdateEvents)
  const response: any = await functionToRun()
  await nextTick() // waits for DOM to finish updating
  showSpinner(false)
  return response
}

export function createLoadingItemsUpdatedListener(targetFunction: EventListener): void {
  addComponentScopeEventListener(targetFunction, 'loadingItemsUpdated')
}

export function incrementLoadingItemsUpdatedListener(): void {
  globalEvent('loadingItemsUpdated', null)
}

export function createToolTipListener(targetFunction: EventListener) {
  addComponentScopeEventListener(targetFunction, 'showGlobalToolTip')
}

export function showToolTip(show: boolean, text?: string): void {
  // Create and emit new global event to display a floating new tooltip from App.vue
  globalEvent('showGlobalToolTip', { show: show, text: text || '' })
}

export function orderUpdated(updatedOrder: LocalOrder): void {
  // Create and emit new global event to update a local order variable
  globalEvent('orderUpdated', { order: updatedOrder })
}

export function createUpdatedOrderListener(targetFunction: EventListener): void {
  // Create & remove event listeners for successfully updated orders
  addComponentScopeEventListener(targetFunction, 'orderUpdated')
}

export function itemUpdated(item: LocalOrderItem): void {
  // Create and emit new global event to update local order item
  globalEvent('itemUpdated', { item: item })
}

export function createUpdateditemListener(targetFunction: EventListener): void {
  // Create & remove event listeners for successfully updated item
  addComponentScopeEventListener(targetFunction, 'itemUpdated')
}

export function itemsUpdated(): void {
  // Create and emit new global event to update local order items
  globalEvent('itemsUpdated', {})
}

export function createUpdateditemsListener(targetFunction: EventListener): void {
  // Create & remove event listeners for successfully updated items
  addComponentScopeEventListener(targetFunction, 'itemsUpdated')
}

export function emitSkusUpdated(): void {
  // Create and emit new global event to update local skus variable
  globalEvent('skusUpdated', {})
}

export function createUpdatedSkusListener(targetFunction: EventListener): void {
  // Create & remove event listeners for successfully updated skus
  addComponentScopeEventListener(targetFunction, 'skusUpdated')
}

export function emitSkuUpdated(sku: LocalSku): void {
  // Create and emit new global event to update local skus variable
  globalEvent('skuUpdated', { sku: sku })
}

export function createUpdatedSkuListener(targetFunction: EventListener): void {
  // Create & remove event listeners for successfully updated skus
  addComponentScopeEventListener(targetFunction, 'skuUpdated')
}

export function emitAddNewSkuToItem(itemIdAwaitingNewSKU: number, sku: LocalSku): void {
  globalEvent('newSkuForItemAdded', { itemID: itemIdAwaitingNewSKU, sku: sku })
}

export function createAddNewSkuToItemListener(targetFunction: EventListener): void {
  addComponentScopeEventListener(targetFunction, 'newSkuForItemAdded')
}

export function createNewItemSkuListener(itemId: number | undefined, action: Function): void {
  const updateItemSKU = (event: Event) => {
    const detail = (event as CustomEvent).detail
    if (itemId && detail.itemID === itemId) action(detail.sku)
  }
  createAddNewSkuToItemListener(updateItemSKU)
}

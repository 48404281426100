<template>
  <input-component-base
    :label="label"
    :name="name"
    :class="$attrs.class"
    :tooltip="tooltip"
    data-testid="date-input-test-id"
    :user-input-visible="editable"
    :show-divider="showDivider"
  >
    <div
      :class="{
        'w-full shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600':
          editable || false
      }"
      class="flex rounded-md sm:max-w-md"
    >
      <input
        type="date"
        :name="name"
        :id="id"
        :required="required && editable"
        :disabled="!editable"
        class="text-center block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-5"
        :value="dateText || ''"
        @input="dateInputUpdated"
      />
    </div>
  </input-component-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import InputComponentBase from '@/components/inputComponents/InputComponentBase.vue'

export default defineComponent({
  name: 'DateInput',
  components: { InputComponentBase },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    dateText: {
      value: [String, null],
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      value: [String, Number, null],
      default: ''
    },
    name: {
      value: String,
      default: 'date-input'
    },
    tooltip: {
      type: String,
      required: false
    }
  },
  methods: {
    dateInputUpdated($event: Event) {
      this.$emit('update:dateText', ($event.target as HTMLInputElement).value)
    }
  }
})
</script>

<template>
  <div class="flex min-h-full overflow-auto justify-center">
    <div class="flex flex-col justify-evenly py-4 px-4 sm:px-24 sm:flex-none">
      <header-image class="flex h-auto px-2" />
      <div
        :class="{ 'logging-in': signingIn || false }"
        class="flex flex-col transition mt-6 mx-auto w-full max-w-sm sm:w-96 mb-10 sm:mb-40"
      >
        <div>
          <div class="text-center">
            <p class="text-lg font-medium text-gray-700">Sign in</p>
          </div>
          <div class="text-center">
            <p
              class="error-text text-sm font-medium text-gray-700"
              :class="invalidCredentials ? 'opacity-100' : 'opacity-0'"
            >
              Invalid User Credentials
            </p>
          </div>

          <div>
            <form v-on:submit.prevent="login(user)" class="space-y-6">
              <!-- submit.prevents stops page from posting and refreshing -->
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">Username</label>
                <div class="mt-1 relative w-full">
                  <input
                    @focusin="invalidUserName = false"
                    :disabled="signingIn"
                    :class="{ 'error-shadow': invalidUserName }"
                    v-model="user.name"
                    id="username"
                    name="username"
                    type="text"
                    autocapitalize="none"
                    autocomplete="text"
                    required
                    class="transition block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div class="space-y-1">
                <label for="password" class="block text-sm font-medium text-gray-700"
                  >Password</label
                >
                <div
                  class="mt-1 relative w-full capslock-warning"
                  :class="{ 'show-capslock-warning': capslockIsOn }"
                >
                  <input
                    @focusin="invalidPassword = false"
                    :disabled="signingIn"
                    :class="{ 'error-shadow': invalidPassword }"
                    v-model="user.password"
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="transition block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div class="flex items-center justify-evenly">
                <div class="flex items-center">
                  <input
                    :checked="saveUserName"
                    @input="saveUserName = ($event.target as HTMLInputElement).checked || false"
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label for="remember-me" class="ml-2 block text-sm text-gray-900"
                    >Remember me</label
                  >
                </div>
              </div>

              <div class="pt-6">
                <button
                  name="submit"
                  id="submit"
                  :disabled="signingIn"
                  type="submit"
                  class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {{ signingIn ? 'Signing in...' : 'Sign in' }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        style="background: url(/background.jpg); background-size: cover; background-position: left"
        src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
        alt="background"
      />
    </div>
  </div>
</template>

<script lang="ts">
import HeaderImage from '@/components/HeaderImage.vue'
import csawfApi from '@/csawf-api'
import router from '@/router'
import { defineComponent, nextTick } from 'vue'
import { createCapsLockListener, showSpinner } from '@/composables'
import { errorHandler, isLoginError } from '@/services/ErrorHandler'
import type { LocationQueryValue } from 'vue-router'

type User = { name: string; password: string }

export default defineComponent({
  name: 'LoginView',
  computed: {
    rememberMe: {
      get() {
        return localStorage.getItem('csawfRememberMe')
      },
      set(value: string | null) {
        if (value && value.length > 0) {
          localStorage.setItem('csawfRememberMe', value)
        } else {
          localStorage.removeItem('csawfRememberMe')
        }
      }
    }
  },
  components: {
    HeaderImage
  },
  data() {
    return {
      user: { name: '', password: '' } as User,
      invalidCredentials: false as boolean,
      invalidUserName: false as boolean,
      invalidPassword: false as boolean,
      signingIn: false as boolean,
      saveUserName: false as boolean,
      capslockIsOn: false as boolean
    }
  },
  methods: {
    async login(user: User) {
      try {
        this.setLoading(true)
        await this.attemptLoginWithUserCredentials(user)
      } catch (error) {
        this.handleLoginFailure(error)
      } finally {
        this.setLoading(false)
      }
    },
    async attemptLoginWithUserCredentials(user: User) {
      await this.verifyCredentials(user)
      await this.redirectLoggedInUser()
    },
    handleLoginFailure(error: any) {
      if (isLoginError(error)) this.invalidCredentials = true
      else errorHandler(error)
    },
    async verifyCredentials(user: User) {
      this.invalidCredentials = false
      await csawfApi.login(user.name, user.password)
      this.rememberMe = this.saveUserName ? user.name : null
    },
    async redirectLoggedInUser() {
      await this.redirectRoute()
      await nextTick()
      showSpinner(false)
    },
    async redirectRoute() {
      return await new Promise((resolve) => {
        router.push({ path: this.getRedirectPath(this.$route?.query?.redirect) }).then(resolve)
      })
    },
    getRedirectPath(redirects: LocationQueryValue | LocationQueryValue[] | undefined): string {
      if (Array.isArray(redirects) && redirects?.[0]?.toString()) return redirects[0].toString()
      else if (redirects && redirects.toString()) return redirects.toString()
      else return '/'
    },
    setLoading(loading: boolean) {
      showSpinner(loading)
      this.signingIn = loading
    }
  },
  created() {
    this.user.name = this.rememberMe || ''
    this.saveUserName = this.rememberMe !== null || false
    createCapsLockListener((capsLockIsON: boolean) => (this.capslockIsOn = capsLockIsON))
  },
  watch: {
    invalidCredentials: {
      handler: async function (newVal: boolean) {
        this.invalidUserName = newVal
        this.invalidPassword = newVal
      },
      deep: true,
      immediate: false
    }
  }
})
</script>

<style scoped>
.capslock-warning::before {
  content: 'Caps-Lock is ON';
  font-weight: 500;
  font-size: 0.75rem;
  color: red;
  position: absolute;
  right: 0.25rem;
  bottom: 100%;
  margin-bottom: 0.25rem;
  font-style: italic;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.show-capslock-warning::before {
  opacity: 0.8;
}

.transition {
  transition:
    box-shadow 1s,
    opacity 1s;
}

.error-shadow {
  box-shadow: 0 0 0.5rem 0 red !important;
}

.logging-in {
  opacity: 40%;
  filter: blur(2px);
  pointer-events: none;
}

.error-text {
  transition:
    box-shadow 1s,
    opacity 1s;
  color: red;
  height: 1.75rem;
  padding-top: 0.5rem;
}
</style>
